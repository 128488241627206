import { Box, InputAdornment, TextField, Typography, styled } from "@mui/material";
import PageBox from "../components/layout/pageBox";
import { Link } from "react-router-dom";
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import React, { useEffect, useMemo, useState } from "react";
import { priceFormatter } from "../components/tvchart/chart";
import { User } from "../components/cards/user";
import axios from "axios";
import { PULSECHAIN_CONTRACT_ADDRESS, PULSECHAIN_RPC_URL, SERVER_URL } from "../abis/constants";
import { ethers, formatEther } from "ethers";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import fairLaunchFactoryABI from "../abis/FairLaunchFactoryABI.json";
const RANKING_COLORS = ['#FFBD2B', '#B0B5B3', '#D68E4D']

const Row = styled(Box) <{ connected: boolean }>`
    ${({ connected }) => connected
        ? `background: linear-gradient(#171228, #171228) padding-box,
                linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;`
        : 'background: #00000030;'
    }
    border-radius: 16px;
    border: 1px solid transparent;
    display: grid;
    grid-template-columns: 22em 22em 22em 25em;
    align-items: center;
    padding: 0.2em 2em;
    &.ranked {
        ${({ connected }) => connected
        ? `background: linear-gradient(#2b263f, #2b263f) padding-box,
                    linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;`
        : 'background: #2b263f;'
    }        
    }
    &:hover {
        background: linear-gradient(#171228, #171228) padding-box,
            linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    }
    &.ranked:hover {
        background: linear-gradient(#2b263f, #2b263f) padding-box,
            linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    }
    ${({ theme }) => theme.breakpoints.down("sm")} {
        grid-template-columns: 5em 7em 7em 7em;
    }
`

const Header = styled(Box)`
    border-radius: 16px;
    background: linear-gradient(180deg, #493496 0%, #211744 100%) padding-box,
        linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    border: 1px solid transparent;
    font-family: Riffic Free;
    display: grid;
    grid-template-columns: 22em 22em 22em 25em;
    align-items: center;
    padding: 0.8em 2em;
    ${({ theme }) => theme.breakpoints.down("sm")} {
        grid-template-columns: 6em 7em 7em 7em;
    }
`

const Ranking = styled('span') <{ order: number }>`
    color: white;
    font-family: Riffic Free;
    width: 2.5em;
    height: 2.5em;
    border-radius: 2em;
    text-align: center;
    line-height: 2.5em;
    border: 1px solid transparent;
    background: ${({ order }) =>
        RANKING_COLORS[order] ? `
            linear-gradient(180deg, ${RANKING_COLORS[order]} 0%, ${RANKING_COLORS[order]} 100%) padding-box,
            linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box
        ` : '#51419E'
    };
`

const Coundown = styled(Box)`
    display: flex;
    align-items: stretch;
    justify-content: center;
    color: #AAA;
    font-family: Riffic Free; 
    & > div {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }
    label {
        color: white;
        font-size: 1.2rem;
    }
`

const TimeBox = styled(Box) <{ fixed?: boolean }>`
    display: flex;
    flex-direction: column;
    border-radius: 26px;
    background: linear-gradient(180deg, #493496 0%, #211744 100%) padding-box,
        linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
    border: 4px solid transparent;
    font-family: Riffic Free;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    color: white;
    h2 {
        font-size: 3em;
        margin: 0;
    }
    span {
        font-size: 1em;
    }
    height: 8em;
    ${({ fixed }) => fixed ? `
        width: 8em;
    ` : `
        padding: 0 2rem;
    `}
`

const BlackBox = styled(Box)`
    flex: 1;
    min-height: 8rem;
    background: #110B28;
    border-radius: 16px;
`

const SearchBox = styled(TextField)`
    align-self: stretch;
    & .MuiOutlinedInput-root {
        background: #110B28;
        border-radius: 16px;
        font-size: 1em;

        & fieldset {
            border-color: transparent !important;
        }
    }
`;

const MyRank = styled(Row)`
    align-self: stretch;
    justify-content: space-between;
    padding: 0.3em 0.5em;
`

export default function LeaderboardPage() {
    const ownerWallet = "0x0be53d9FA63FD5817acd216c5B77514417D138FA"
    const provider = new ethers.JsonRpcProvider(PULSECHAIN_RPC_URL)
    const [ownerBalanceInUSD, setOwnerBalanceInUSD] = useState("0")
    const [ownerBalanceInPLS, setOwnerBalanceInPLS] = useState(0)
    const { address } = useWeb3ModalAccount();
    const [searchWord, setSearchWord] = React.useState('');
    const [totalPoints, setTotalPoints] = useState(0)
    const [showUsd, setShowUsd] = useState(false)


    const [users, setUsers] = useState<any[]>([])
    // Set the initial date (5th September 2024) and add 72 hours to it
    // const initialDate = new Date(); // 5th September 2024 at midnight
    const targetDate = new Date('2024-10-29T16:00:00.000Z'); // Add 72 hours


    // Initialize state for remaining time
    // Initialize state for remaining time
    const [remainingTime, setRemainingTime] = useState(Number(targetDate) - Number(new Date()));

    const searchTokenInputProps = React.useMemo(() => ({
        startAdornment: (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        ),
        endAdornment: (
            <InputAdornment style={{ cursor: "pointer", visibility: searchWord.length > 0 ? 'visible' : 'hidden' }} position="end" onClick={() => {
                setSearchWord('')
            }}>
                <CloseIcon sx={{ height: 18 }} />
            </InputAdornment>
        )
    }), [searchWord])

    // Function to calculate and update remaining time
    const calculateRemainingTime = () => {
        const now = new Date();
        const diff = Number(targetDate) - Number(now);
        setRemainingTime(diff);
    };

    // Convert remaining time into hours, minutes, and seconds
    // const getTimeComponents = (time: any) => {
    //     const totalSeconds = Math.floor(time / 1000);
    //     const days = Math.floor(totalSeconds / 86400);
    //     const hours = Math.floor(totalSeconds / 3600);
    //     const minutes = Math.floor((totalSeconds % 3600) / 60);
    //     const seconds = totalSeconds % 60;
    //     return { days, hours, minutes, seconds };
    // };

    // const { days, hours, minutes, seconds } = getTimeComponents(remainingTime);

    const getTimeComponents = (time: any) => {
        const totalSeconds = Math.floor(time / 1000);
        const days = Math.floor(totalSeconds / 86400);
        const hours = Math.floor((totalSeconds % 86400) / 3600); // Adjusted to account for days
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
        return { days, hours, minutes, seconds };
    };

    // const remainingTime = targetDate.getTime() - new Date().getTime();
    const { days, hours, minutes, seconds } = getTimeComponents(remainingTime);

    // Run useEffect to update the countdown every second
    useEffect(() => {
        const interval = setInterval(() => {
            calculateRemainingTime();
        }, 1000);

        const tempFairLaunchContract = new ethers.Contract(PULSECHAIN_CONTRACT_ADDRESS, fairLaunchFactoryABI, provider);
        tempFairLaunchContract.getETHPriceByUSD().then((price) => {
            const parsedPrice = Number(formatEther(price.toString()))
            provider.getBalance(ownerWallet).then((balance) => {
                const parsedBalanceInPLS = Number(formatEther(balance.toString()))
                const balanceInUSD = parsedPrice * parsedBalanceInPLS
                setOwnerBalanceInUSD(priceFormatter(balanceInUSD, 2, false))
                setOwnerBalanceInPLS(parsedBalanceInPLS)
            });
        });

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    useEffect(() => {
        axios.get(`${SERVER_URL}/users/ranking`).then(({ data: users }) => {
            setUsers(users)
            let total = 0
            for (let i = 0; i < users.length; i++) {
                total += Number(users[i].ranking)
            }
            setTotalPoints(total)
        })
    }, [])

    const handleSwitch = () => {
        setShowUsd(!showUsd)
    }

    const pointsToPLS = () => {
        return Number(ownerBalanceInPLS) / totalPoints
    }
    const pointsToUsd = () => {
        // console.log({
        //     ownerBalance: Number(ownerBalance.replace(',', '')),
        //     totalPoints,
        //     t: Number(ownerBalance.replace(',', '')) / totalPoints
        // })
        return Number(ownerBalanceInUSD.replace(',', '')) / totalPoints
    }

    const myRank = useMemo(() => {
        return users?.findIndex((user: any) => user.address == address) ?? -1
    }, [users, address])

    return (
        <PageBox>
            <Box display="flex" justifyContent="flex-end">
                <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <ArrowLeftIcon sx={{ color: 'white', height: 24 }} />
                    <Typography sx={{ color: 'white', textDecoration: 'none' }} fontSize="small">Go back</Typography>
                </Link>
            </Box>
            <Coundown gap="2em" flexDirection={{ xs: "column", sm: "column", md: "row" }}>
                <div>
                    <label>Time left until next airdrop</label>
                    <Box display="flex" alignItems="center" gap="0.8rem">
                        <TimeBox fixed>
                            <h2>{days}</h2>
                            <span>Day{days > 1 ? 's' : ''}</span>
                        </TimeBox>
                        :
                        <TimeBox fixed>
                            <h2>{hours}</h2>
                            <span>Hour{hours > 1 ? 's' : ''}</span>
                        </TimeBox>
                        :
                        <TimeBox fixed>
                            <h2>{minutes}</h2>
                            <span>Minute{minutes > 1 ? 's' : ''}</span>
                        </TimeBox>
                        {/* <TimeBox>
                            <Typography fontSize={'2rem'} color="white" fontFamily="Riffic Free">{seconds}</Typography>
                            <Typography color="white" fontFamily="Riffic Free">Seconds</Typography>
                        </TimeBox> */}
                    </Box>
                </div>
                <div>
                    <label>Total payout</label>
                    <TimeBox>
                        <h2 style={{ marginTop: '20px' }}>${ownerBalanceInUSD}</h2>
                        <h4 style={{ marginTop: '1px' }}>Total Paid Out: $45,276</h4>
                    </TimeBox>
                </div>
            </Coundown>
            <Box gap="1em 2em" display="flex" flexDirection={{ xs: "column", sm: "column", md: "row" }} alignItems={{ xs: "stretch", sm: "stretch", md: "flex-end" }} mt="2em">
                {
                    myRank > -1 &&
                    <>
                        <Typography display={{ xs: 'inline', sm: 'none' }} style={{ fontSize: '20px' }} color="white" fontFamily="Riffic Free" alignSelf="center">Your Rank</Typography>
                        <MyRank connected={true} display={{ xs: 'flex', sm: 'none' }} sx={{ p: "0.5em 2em" }}>
                            <Ranking order={myRank}>{myRank + 1}</Ranking>
                            <Box display="flex" alignItems="center">
                                <User user={users[myRank]} color="#9E9E9E" size={18} />
                            </Box>
                            {showUsd ? <Typography color="white" fontFamily="inherit">${(pointsToUsd() * Number(users[myRank]?.ranking)).toFixed(2)}</Typography> : <Typography color="white" fontFamily="inherit">{priceFormatter(users[myRank]?.ranking, 0, true, true)}</Typography>}
                        </MyRank>
                    </>
                }
                <BlackBox >
                    <Typography style={{ marginLeft: '1rem', marginTop: '0.5rem' }} fontSize={{ sm: '20px', xs: '16px' }} color="white" fontFamily="Riffic Free">📈 +10 points per $1 buy</Typography>
                    <Typography style={{ marginLeft: '1rem', marginTop: '0.5rem' }} fontSize={{ sm: '20px', xs: '16px' }} color="white" fontFamily="Riffic Free">📉 -4 points for $1 sell order</Typography>
                    <Typography sx={{
                        marginLeft: '1rem',
                        marginTop: '0.5rem',
                        cursor: 'pointer',
                        fontSize: { sm: '20px', xs: '16px' },
                        color: 'white',
                        fontFamily: 'Riffic Free',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }} style={{ marginLeft: '1rem', marginTop: '0.5rem', cursor: 'pointer' }} fontSize={{ sm: '20px', xs: '16px' }} color="white" fontFamily="Riffic Free" onClick={() => {
                        window.location.href = "https://coin.quest/profile/me/referral"
                    }}>👥 Earn 10% more for you and your friend with a <span style={{ textDecoration: 'underline' }}>referral code!</span></Typography>
                </BlackBox>
                <Box gap="1em 2em" display="flex" flexDirection={{ xs: "column", sm: "column", md: "column" }} alignItems="center" mt={{ sm: "2em", xs: 0 }}>
                    <Typography visibility={myRank > -1 ? "visible" : "hidden"} display={{ sm: 'inline', xs: 'none' }} style={{ fontSize: '20px' }} color="white" fontFamily="Riffic Free">Your Rank</Typography>
                    <MyRank connected={true} visibility={myRank > -1 ? "visible" : "hidden"} display={{ xs: 'none', sm: 'flex' }}>
                        <Ranking order={myRank}>{myRank + 1}</Ranking>
                        <Box display="flex" alignItems="center">
                            <User user={users[myRank]} color="#9E9E9E" size={18} />
                        </Box>
                        {showUsd ? <Typography color="white" fontFamily="inherit">${(pointsToUsd() * Number(users[myRank]?.ranking)).toFixed(2)}</Typography> : <Typography color="white" fontFamily="inherit">{priceFormatter(users[myRank]?.ranking, 1)}</Typography>}
                    </MyRank>
                    <SearchBox placeholder="Search" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} InputProps={searchTokenInputProps} />
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" gap="8px" py="1.5em">
                <Header>
                    <Typography color="white" fontFamily="inherit">Ranking</Typography>
                    <Typography color="white" fontFamily="inherit">Points</Typography>
                    <Typography color="white" fontFamily="inherit">User</Typography>
                    <Typography onClick={handleSwitch} style={{ cursor: 'pointer' }} color="white" fontFamily="inherit">
                        <span style={{ color: showUsd ? 'white' : 'grey' }}>USD</span>/<span style={{ color: showUsd ? 'grey' : 'white' }}>PLS</span>
                    </Typography>
                </Header>
                {
                    users.length > 0 && users
                        .filter((user: any) => user.username.toLowerCase().includes(searchWord.toLowerCase()) ||
                            user.address.toLowerCase().includes(searchWord.toLowerCase())
                        )
                        .map((user: any, i: number) => (
                            <Row key={`rank-${user.rank}`} className={user.rank < 3 ? 'ranked' : ''} connected={user.address === address}>
                                <Ranking order={user.rank}>{user.rank + 1}</Ranking>
                                <Typography color="white" fontFamily="inherit">{priceFormatter(user.ranking, 1)}</Typography>
                                <Box display="flex" alignItems="center">
                                    <User user={user} color="#9E9E9E" size={18} />
                                </Box>
                                {showUsd ? <Typography color="white" fontFamily="inherit">${(pointsToUsd() * Number(user.ranking)).toFixed(2)}</Typography> : <Typography color="white" fontFamily="inherit">{priceFormatter(pointsToPLS() * Number(user.ranking), 0, true, true)}</Typography>}
                            </Row>
                        ))
                }
            </Box>
        </PageBox>
    )
}